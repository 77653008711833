import React,{useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import './Memorizing.css'
import {Moshaf, onUpdate, waqafaatUrl} from './Moshaf'
//import useCached from '../Shared/CacheHook';

// Full screen ...
const MemorizeControl = ({role, page, onPrev, onNext, onPage, pages, Message}) => {
    var audio = document.getElementById("tilaawa")
    var image = document.getElementById("image")

    const [play, Play] = useState(false)
    const [loaded, Load] = useState(false)
    const [expanded, Expand] = useState(false)
    const [repeat, Repeat] = useState({i:0, n:1})
    const [speed, Speed] = useState(2)
    const [part, Part] = useState(-1) // for audio
    const [selected, Select] = useState(-1)  // for image
    const [clicked, Clicked] = useState({n:0,x:0,y:0,now:new Date()})
    const [moved, Moved] = useState({x:0,y:0})
    const [moving, Moving] = useState(-1)
    const [tilaawa, Tilaawa] = useState("")
    const [title, Title] = useState("")
    const [items , Items] = useState([])
    const [all, All] = useState(false)
    const [touch, Touch] = useState({x:0,y:0})
    
    let istyle = {display: 'flex', justifyContent: 'center'}
    let tstyle = {position:'absolute', width: '10%', height: '5%', fontWeight:'bold', color:'darkred'}

    const name=page.toString().padStart(3,'0')+'.jpg'
    const safha = waqafaatUrl+name

    //const { safhaCached, safhaLoading, safhaError } = useCached(safha)

    useEffect(()=>{        
        Items([])
        Load(false)
        Part(-1)
        Play(false)

        if(page>0) {
            Moshaf(`/waqafaat/${page}`, Items, Load, Message)
        }

    },[page])

    useEffect(()=>{
        if(part>=0) {
            var sheader=document.getElementById("Header").style
            var simage=document.getElementById("image").style
            if(expanded){
                simage.maxHeight = "calc((100vh - 2rem) - 20px)"
                sheader.height = "0rem"
                sheader.visibility="hidden"
            }
            else {
                simage.maxHeight = "calc((100vh - 8rem) - 20px)"
                sheader.visibility="visible"
                sheader.height = "4rem" 
            }
        }
    },[expanded]) 
    
    useEffect(()=>{
        if(audio) {
            audio.playbackRate=speed
            //alert(speed)
        }
    },[speed])


    const onSpeed = e => {
        e.preventDefault()
        let value=parseInt(e.target.value)
        Speed(value)        
    }

    const onRepeat = e => {
        e.preventDefault()
        Repeat({i:0, n:parseInt(e.target.value)})
        //console.log('onRepeat ', e.target.value)
    }    

    const onTilaawa = (item) => {  
        if(item) {
            let p = items.findIndex(s=>s.Id===item.Id)
            //let sms=`selected:${selected} clicked:${p} Locked:${items[p].Locked}`; console.log(sms)//; alert(sms)

            if(p>=0) {
                items[p].Locked = (items[p].Locked>0)?0:1
    
                if(selected>=0) {
                    if(p>selected) {
                        if(items[p].Locked===items[selected].Locked) {
                            for(let i=selected+1; i<p; i++) {
                                items[i].Locked = items[selected].Locked
                            }
                        }
                        
                            for(let i=0; i<selected; i++) {
                                items[i].Locked = 0
                            }
                            for(let i=p+1; i<items.length; i++) {
                                items[i].Locked = 0
                            }
                        
                    }
                    else if(p<selected) {
                        if(items[p].Locked===items[selected].Locked) {
                            for(let i=p+1; i<selected; i++) {
                                items[i].Locked = items[selected].Locked
                            }
                        }
                        
                            for(let i=0; i<p; i++) {
                                items[i].Locked = 0
                            }
                            for(let i=selected+1; i<items.length; i++) {
                                items[i].Locked = 0
                            }
                        
                    }
                }
            }

            Select(p)

            if(play) {
                onPlay()
                //console.log('item', p, item.Locked, items[p])
            }
        }
    }

    useEffect(()=>{
        if(play) {
            audio.playbackRate=speed
            
            audio.play()   
        }     
    },[tilaawa])

    useEffect(()=>{
        if(play && part>=0 && part<items.length && repeat.i<repeat.n) {
            //alert(`useEffect repeat: ${repeat.i}/${repeat.n} - part: ${part}`)

            let item = items[part]
            let sah=`${item.Soora.toString().padStart(3,'0')}/${item.Sah}.mp3` 
            let file = safha.replace(name,sah) 

            Title(`${item.Hizb??""} ${item.Tomon??""}`)
            Tilaawa(file)
            
            if(file===tilaawa) {  // important for repeat ! 230122 
                audio.playbackRate=speed
                //audio.play() // Commented at 240218 but this solve an issue detected at 240314  and commented at 240320
            }
        }
    },[part, repeat])
 
const Delay = ms => new Promise(res => setTimeout(res, ms))

async function onEnded() {
    //console.log('onEnded Repeat',repeat,part,items); alert(`onEnded ${play} ${repeat.i} ${repeat.n} ${part}`)

    if(play && repeat.i<repeat.n) 
    {
        for(let p=part+1; p<items.length; p++) {
            if(items[p].Locked>0) {
                await Delay(Math.trunc(600/speed))
                Part(p)
                return
            }
            else if(p+1===items.length) { // Added  at 240320 great caught  
                //alert(`delay ${repeat.i} - ${repeat.n}`)
                await Delay(Math.trunc(6000/speed))    
            }
        }
        
        Repeat({...repeat, i:repeat.i+1})

        if(repeat.i+1===repeat.n) {
            //alert(`delay ${repeat.i} - ${repeat.n}`)

            onPlay()
        }
        else {
            for(let p=0; p<items.length; p++) {
                if(items[p].Locked>0) {
                    //alert(`onEnded 2 ${p} ${items[p].Locked} ${items.length} ${part}`)
                    Part(p)
                    return;
                }
            }
        }
    }
}         

const onPlay = () => {
    if(!play) {
        let locked=-1
        Repeat({...repeat, i:0})
        for(let p=0; p<items.length; p++) {
            if(items[p].Locked>0) {
                locked=p
                break
            }
        }
        
        if(locked<0 && items.length>0)
        {
            locked=0
            for(let p=0; p<items.length; p++)
                items[p].Locked=1

            All(true)
        }
        
        if(locked>=0)
        {
            Part(locked)
            audio.playbackRate=speed
            audio.style.visibility='visible'  
            Play(true)  
        }
    }
    else {
        audio.pause()
        audio.style.visibility='hidden'
        Play(false)
        if(all) {
            for(let p=0; p<items.length; p++)
                items[p].Locked=0

            All(false)
        }
    }
}

const parseMin = (min) => {
    if(min.includes('px'))
        return min
    if(min.includes(',')) {

        let cr = image.getBoundingClientRect()
        if(cr && cr.right>0 && cr.bottom>0) {
            let pane = document.getElementById("ImagePane").getBoundingClientRect()

            let w=parseInt(cr.right-cr.left)
            let h=parseInt(cr.bottom-cr.top)

            let xy = min.split(',')
            let top = parseInt(xy[0]*h/100)+'px'
            let right = parseInt(xy[1]*w/100 + (pane.right-cr.right))+'px'
            return {top, right}
        }
    }
}

useEffect(()=>{
    if(loaded && items && items.length>0) {
        let step = Math.round(100/(items.length/2+1))

        //console.log('Step',step,items.length)
        for(let p=0; p<items.length; p++) { 
            items[p].Locked = 0

            let min = items[p].Min ?? ''
            if(min.includes(',') && !min.includes('px')) {
                items[p].Min = parseMin(min)
            }
            else {
                let t = 2 + Math.trunc(p/2) * step
                let r = 2 + 50 * (p%2)

                min = `${t},${r}`
                items[p].Min = parseMin(min)
            }
            //console.log(items[p].Min)
        }
        Part(items.length)   
    }
},[loaded])

/*
// MeR onMove 
useEffect(()=>{
    
},[moved])
*/
const onMouseDown = (item) => {
    if(role>6) {
        if(moving<0) {
            let p = items.findIndex(s=>s.Id===item.Id)
            Moving(p)        
            Moved({x:0, y:0})
            //console.log("onMouseDown", p)
        }
    }
    //else console.log("unauthorized")
}

const onMove = (event) => {

    if(role>6 && moving>=0) {
        //let er = event.currentTarget.getBoundingClientRect()            
        let ir = image.getBoundingClientRect()
        if(ir && ir.right>0 && ir.bottom>0) {
            //console.log("onMove event", event.clientX, event.clientY, "current", er.left, er.top, "image", ir.left, ir.top)

            let w=parseInt(ir.right-ir.left)
            let h=parseInt(ir.bottom-ir.top)

            let x = parseInt((event.clientX-ir.left+20)*100/w)||0
            let y = parseInt((event.clientY-ir.top-10)*100/h)||0
            let min = `${y},${100-x}`

            items[moving].Min = parseMin(min)
            Moved({x,y})

            if(role===9 && moving!==part) {
                //alert(moving)
                //onPlay()
                let item = items[moving]
                let sah=`${item.Soora.toString().padStart(3,'0')}/${item.Sah}.mp3` 
                let file = safha.replace(name,sah) //; alert(file) 
                Tilaawa(file) 
                audio.playbackRate=4
                if(tilaawa===file) {
                    audio.play()       
                }
            }
        }
    }
}

const  onMouseUp = (event) => {
    if(role>6 && moving>=0) {

        Moving(-1)        
        
        if(moved.x>0 || moved.y>0) {
            let min = `${moved.y},${100-moved.x}`
            onUpdate(items[moving].Id, "Min", min)
            items[moving].Min = parseMin(min)

            if(role===9 && moving!==part) {
                audio.pause()
            }
        }
    }
}


const onClick = (event) => {
    if(role<6) {
        //console.log("unauthorized")
        return
    }
            
    let cr = event.currentTarget.getBoundingClientRect()
    if(cr && cr.right>0 && cr.bottom>0) {
        let w=parseInt(cr.right-cr.left)
        let h=parseInt(cr.bottom-cr.top)
    
//let message= `onClick (event x ${event.clientX}, y ${event.clientY})`; console.log(message, cr, clicked)//; alert(message)

        let n = clicked.n
        let x = parseInt((event.clientX-cr.left)*100/w)||0
        let y = parseInt((event.clientY-cr.top)*100/h)||0
        let now = new Date()

        if(Math.abs(clicked.x-x)<3 && Math.abs(clicked.y-y)<3) {
            let ms=now.getTime()-clicked.now.getTime()
            if(ms<500) {
                n=n+1

                if(n>0 && selected>=0) {  // 2 or 3 click !
                    //Fetch clicked
                    let min = `${clicked.y},${100-clicked.x}`
                    onUpdate(items[selected].Id, "Min", min)

                    items[selected].Min = parseMin(min)
                    //console.log('onClick item.Min', min, items[selected].Min, clicked); 
                }
            }
            else
                n=0
        }
        else
            n=0
            
        Clicked({n,x,y,now})  
        }
    }

    const onTouchStart = (event) => {
        //console.log("onTouchStart", event); //alert("onTouchStart")
        let ts = event.targetTouches[0] // react
        Touch({x: ts.clientX, y:ts.clientY})
    }

    const onTouchEnd  = (event) => {
        let te = event.changedTouches[0] // react
        //alert(`onTouchEnd X e ${te.clientX} s ${touch.x} / Y e ${te.clientY} s ${touch.y} ?`)
        if(te.clientX>touch.x+50)
            onNext()
        else if(te.clientX<touch.x-50)
            onPrev()
    }

    const onSafha = (event) => {
        onPage(event)
    }

return (

    (page>0 && safha && safha.length>0) ? 

    <>
<div className="row" id="memorizeHeader">

<select id="safahaat" style={{ ...tstyle, top:0, left:0, width:'4rem', height: '2rem', opacity:'0.6'}} 
    onChange={onSafha} value={page} > 
{ pages.length>0 && pages.map((item) => (    
    <option key={'Safha-'+item.Course+'-'+item.Safha} value={item.Safha}>{item.Safha}</option>
))}
</select>

<audio id="tilaawa" controls="controls" autobuffer="autobuffer"
onEnded={onEnded}
style={{position:'absolute', left:'20%', width:'60%', height:'2rem', top:0, visibility:'hidden', opacity:'0.8'}} 
src={tilaawa} />

    <div style={{ ...tstyle, top:'0', left:'25%', height: '2rem' }}>
                <p>{play?repeat.i+1:""} </p>
    </div>
    <Button className="mr-2" variant={play?"danger":"success"}  
            style={{ position:'absolute', left:'30%', width: '20%', height: '2rem', top:'0', opacity:'0.6' }}
                onClick={onPlay}
                ><i className={play?"fa fa-pause":"fa fa-play"} style={istyle}></i>
    </Button>
    <select id="repeats" style={{ ...tstyle, top:0, left:'55%', width:'3rem', height: '2rem', opacity:'0.6', zIndex:'60'}} 
        onChange={onRepeat} 
        defaultValue={repeat.n}
        >
        <option value='1'>1</option><option value='2'>2</option><option value='3'>3</option><option value='5'>5</option><option value='7'>7</option>
    </select>

    <select id="speeds" style={{ ...tstyle, top:0, left:'70%', width:'3rem', height: '2rem', opacity:'0.6'}} 
        onChange={onSpeed}
        defaultValue={speed}
        >
        <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option>
    </select>
</div>
<div class="row page-center">
        <div className="page-center" id="ImagePane">

        <img className="page-fit-center" alt="القرآن الكريم"  id="image" 
            src={safha} 
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onClick={onClick}
        /> 

{ part>=0 && items?.length>0 && items.map((item, index) => (    
    <Button variant={(item.Locked===1)?((play && index===part)?"danger":"success"):"primary"} key={item.Id} value={item.Id}
    style={{ ...item.Min, position:'absolute', borderRadius: '60%', width:'9.6%', height:'4.8%', opacity:'0.5', border:(selected===index)?'1px solid '+((moving===index)?'red':'gold'):'0px solid'}}
        onClick={()=>onTilaawa(item)}
        onMouseDown={()=>onMouseDown(item)}
        onMouseMove={onMove}
        onMouseUp={onMouseUp}
        ><i style={{...istyle, color:'white'}}>{(role<6)?'':item.Aaya}</i></Button>
))}

        
        <Button className="mr-2 NavButton" variant="warning" 
            style={{ left:0 }}
            onClick={onNext}
            ><i className="fa fa-arrow-left" style={istyle}></i>
        </Button>

        <Button className="mr-2 NavButton" variant="warning" 
            style={{ left:"94%" }}
            onClick={onPrev}
            ><i className="fa fa-arrow-right" style={istyle}></i>
        </Button>

        <Button className="mr-2 MButton" variant={"info"}  
            style={{ aspectRatio:'1 / 1', top:0, left:0 }}
            onClick={()=>Expand(!expanded)}
            ><i className={"fa fa-expand"} style={istyle}></i>
        </Button>    

        </div>
        </div>
    </>
    :  
    <div>Memorizing...</div> 
    )
}

export default MemorizeControl
